<template>
  <Popup ref="popupRef" title="购买会员" width="500px" :show-cancel="false" :show-confirm="false"
  confirm-text="确认支付">
    <div class="label">支付方式：</div>
    <Payway :showBalance="false"/>
    <div class="bottom-row">
      <div class="total">
        <div>合计 <span class="total-num">{{totalPrice}}</span>元 </div>
        <!-- <div class="tips">点击确认支付，代表你同意 <span class="policy">《自助购买协议》</span> </div> -->
      </div>
      <div class="btn-group">
        <div class="btn-cancel btn-border" @click="close">取消</div>
        <div class="btn-confirm btn-fill-theme" @click="confirm">确认支付</div>
      </div>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import Payway from '@/components/Payway'
export default {
  components: {
    Popup,
    Payway
  },
  data() {
    return {
      totalPrice: 0
    }
  },
  methods: {
    open(info) {
      this.totalPrice = info.totalPrice
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    },
    confirm() {
      this.$confirm('暂不支持此功能，请联系客服处理', '温馨提示',{
        confirmButtonText: '去联系'
      }).then(action => {
        if(action == 'confirm') {
          this.$router.push({ name: 'Message', params: {isToCustomer: true}})
        }
      }).catch(err => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #65666F;
  margin-bottom: 8px;
}
.bottom-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  .btn-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn-cancel, .btn-confirm {
      padding: 10px 24px;
      border-radius: 4px;
      font-size: 14px;
      margin-left: 12px;
      cursor: pointer;
    }
  }

  .total {
    font-size: 14px;
    color: var(--theme-color-red);
    .total-num {
      font-size: 24px;
      font-weight: bold;
    }
    .tips {
      font-size: 12px;
      color: #B0B3BF;
      margin-top: 8px;
    }
    .policy {
      color: var(--theme-color);
      cursor: pointer;
    }
  }
}
</style>
<template>
  <div v-if="benefitData">
    <div class="panel top-row" :class="{'bg-normal': userInfo.vipLevel == 1, 'bg-normal': userInfo.vipLevel == 2 }">
      <div class="flex">
        <img class="avatar" :src="userInfo.headImg || require('@/assets/images/avatar-default.png')">
        <div>
          <div class="name">{{userInfo.nickName || ''}}</div>
          <div class="vip">
            <img :src="userInfo.vipLevel == 1 ? require('@/assets/images/vip1.png') : require('@/assets/images/vip2.png')">
            <span v-if="userInfo.vipLevel == 0">开通VIP会员尊享更多权益</span>
            <span v-if="userInfo.vipLevel == 1">您已成为普通会员</span>
            <span v-if="userInfo.vipLevel == 2">您已成为金牌会员</span>
          </div>
        </div>
      </div>
      <div v-if="userInfo.vipLevel == 0">暂未开通会员</div>
      <div v-if="userInfo?.vipPeriodValidity?.vip1ExpireTime">普通会员至{{userInfo.vipPeriodValidity.vip1ExpireTime}}</div>
      <div v-if="userInfo?.vipPeriodValidity?.vip2ExpireTime" class="ml8">黄金会员至{{userInfo.vipPeriodValidity.vip2ExpireTime}}</div>
    </div>
    <div class="panel-card mt16">
      <div class="tab-member">
        <div :class="['tab', isNormal ? 'active':'']" @click="isNormal = true">普通会员</div>
        <div :class="['tab', isNormal ? '':'active']" @click="isNormal = false">金牌会员</div>
      </div>
      <div class="flex justify-between mt16">
        <div :class="['card', isMonth ? isNormal ? 'normal-card':'gold-card' : '']" @click="isMonth = true">
          <div class="name">月卡</div>
          <div class="price">
            <span class="unit">￥</span>
            <span v-if="isNormal">{{benefitData.normalVipPerMonthDesc || monthPrice}}</span>
            <span v-else>{{benefitData.goldenVipPerMonthDesc || monthPrice}}</span>
          </div>
          <div :class="{'txt-through': benefitData.normalPerMonthDescStroke && isNormal || benefitData.goldenPerMonthDescStroke && !isNormal}">{{monthPrice}}元/月</div>
        </div>
        <div :class="['card', !isMonth ? isNormal?'normal-card':'gold-card':'']" @click="isMonth = false">
          <div class="name">年卡</div>
          <div class="price">
            <span class="unit">￥</span>
            <span v-if="isNormal">{{benefitData.normalVipPerYearDesc || yearPrice}}</span>
            <span v-else>{{benefitData.goldenVipPerYearDesc || yearPrice}}</span>
          </div>
          <div :class="{'txt-through': benefitData.normalPerYearDescStroke && isNormal || benefitData.goldenPerYearDescStroke && !isNormal}">{{yearPrice}}元/年</div>
        </div>
      </div>
      <div class="member-desc">
        <div>会员日·VIP月卡限时折扣：</div>
        <div>总数不限量，不限量！</div>
        <div>如有小号、一人多号、模拟器等刷量作弊的行为，将追回违规所得，严重者移交有关部门</div>
        <div>本次活动最终解释权归平台官方所有</div>
      </div>
      <div :class="['btn-purchase', isNormal ? 'normal-btn':'gold-btn']" @click="toPurchase">立即购买 ￥{{totalPrice}}</div>
    </div>
    <!-- 会员权益 -->
    <div class="benefit mt16">
      <div class="header-row">
        <div>会员权益</div>
        <div class="text-center txt-gold">金牌会员</div>
        <div class="text-center">普通会员</div>
        <div class="text-center">非会员</div>
      </div>
      <div class="flex">
        <div class="name">
          <div>在线任务数量(个)</div>
          <div>发布任务服务费(%)</div>
          <div>单次发布最低服务费(元)</div>
          <div>任务币提现手续费(%)</div>
          <div>刷新次数(次)</div>
          <div>任务置顶费用(元/小时)</div>
          <div>任务推荐费用(元/小时)</div>
          <div>店铺置顶费用(元/小时)</div>
          <div>店铺推荐费用(元/小时)</div>
        </div>
        <div class="vals" :class="{'txt-gold': index == 0, 'txt-normal': index == 1}" 
        v-for="(item,index) in benefitList" :key="index">
          <div>{{item.maxTaskNum == 0 ? '不限': item.maxTaskNum}}</div>
          <div>{{item.serveRate * 100}}</div>
          <div>{{item.minServe == 0 ? '不限': item.minServe}}</div>
          <div>{{item.chargeCashRate * 100}}</div>
          <div>{{item.maxTaskRefreshTimes}}</div>
          <div>{{item.topPrice}}</div>
          <div>{{item.recommendPrice}}</div>
          <div>{{item.shopTopPrice}}</div>
          <div>{{item.shopRecPrice}}</div>
        </div>
      </div>
    </div>
    <!-- 购买会员弹窗 -->
    <PurchaseMemberPopup ref="purchasePopupRef"></PurchaseMemberPopup>
  </div>
</template>

<script>
import PurchaseMemberPopup from './components/PurchaseMemberPopup.vue'
import { memberBenefitApi } from '@/api/app.js'
import { mapState } from 'vuex'
export default {
  name: "UserMember",
  components: {
    PurchaseMemberPopup
  },
  data() {
    return {
      benefitList: [],
      benefitData: null,
      isNormal: true,
      isMonth: true,
    }
  },
  computed: {
    ...mapState(['userInfo']),
    monthPrice() {
      if(!this.benefitData) return ''
      return this.isNormal ? this.benefitData.normalVipPerMonth : this.benefitData.goldenVipPerMonth
    },
    yearPrice() {
      if(!this.benefitData) return ''
      return this.isNormal ? this.benefitData.normalVipPerYear : this.benefitData.goldenVipPerYear
    },
    totalPrice() {
      if(!this.benefitData) return ''
      if(this.isMonth) {
        return this.isNormal ? this.benefitData.normalVipPerMonth : this.benefitData.goldenVipPerMonth
      } else {
        return this.isNormal ? this.benefitData.normalVipPerYear : this.benefitData.goldenVipPerYear
      }
    }
  },
  created() {
    this.getBenefitData()
  },
  methods: {
    getBenefitData() {
      memberBenefitApi().then(res => {
        console.log('会员权益', res)
        this.benefitData = res.data
        if( res.data.memberBenefits ) {
          for (const [key, value] of Object.entries(res.data.memberBenefits)) {
            this.benefitList.unshift(value)
          }
        }
      }).catch((err) => {
        this.$tips({ message: err, type: "error" });
      })
    },
    toPurchase() {
      this.$refs.purchasePopupRef.open({totalPrice: this.totalPrice})
    }
  }
}
</script>

<style lang="scss" scoped>
.top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .avatar {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    margin-right: 8px;
  }
  .name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  .vip {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #505E5C;
    img {
      width: 24px;
      height: 12px;
      margin-right: 8px;
    }
  }
}
.bg-normal {
  background-image: url('~@/assets/images/vip1-bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.bg-gold {
  background-image: url('~@/assets/images/vip2-bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.tab-member {
  display: flex;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  margin: 0 -24px;
  border-radius: 16px 16px 0 0;
  background: linear-gradient(to right, #3F2D0C, #311D00);
  .tab {
    flex: 1;
    height: 56px;
    line-height: 56px;
    text-align: center;
    cursor: pointer;
  }
  .active {
    border-radius: 15px 15px 0 0;
    background-color: #fff;
    color: #2A2A2A;
  }
}
// 会员卡
.panel-card {
  border-radius: 16px;
  background-color: #fff;
  padding: 0 24px 24px;
  .card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 466px;
    height: 112px;
    font-size: 12px;
    border-radius: 12px;
    border: 1px solid #EEEEEE;
    cursor: pointer;
    .name {
      font-size: 16px;
    }
    .price {
      font-size: 18px;
      font-weight: bold;
      margin: 8px 0;
      .unit {
        font-size: 12px;
      }
    }

  }
  .normal-card {
    background-color: #EAF3FF;
    color: #516277;
    border: 1px solid #8DA0B8;
  }
  .gold-card {
    background-color: #FCF4E9;
    color: #516277;
    border: 1px solid #FAC861;
  }
  .member-desc {
    font-size: 12px;
    color: #B0B3BF;
    white-space: pre-line;
    margin-top: 24px;
    line-height: 20px;
  }
  .btn-purchase {
    height: 48px;
    line-height: 48px;
    border-radius: 12px;
    font-size: 16px;
    text-align: center;
    margin-top: 24px;
    cursor: pointer;
  }
  .normal-btn {
    color: #fff;
    background: linear-gradient(to right, #94A9C1, #516176);
  }
  .gold-btn {
    color: #432301;
    background: linear-gradient(to right, #FFE58D, #FFBF43);
  }
}
// 权益
.benefit {
  padding: 0 24px;
  border-radius: 16px;
  overflow: hidden;
  background-color: #fff;
  font-size: 14px;
  .header-row {
    display: flex;
    align-items: center;
    height: 46px;
    margin: 0 -24px;
    padding: 0 24px;
    color: #fff;
    background: linear-gradient(to right, #3F2D0C, #311D00);
    &>div {
      flex: 1;
    }
  }
  .name {
    flex: 1;
    color: #505E5C;
    &>div {
      height: 46px;
      line-height: 46px;
    }
  }
  .vals {
    flex: 1;
    text-align: center;
    &>div {
      height: 46px;
      line-height: 46px;
    }
  }
}
.text-center {
  text-align: center;
}
.txt-gold {
  color: #E5A922;
}
.txt-normal {
  color: #2F598C;
}
.txt-through {
  text-decoration: line-through;
}
</style>